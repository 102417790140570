import { MyTildaContainer } from '../../lib/reac-t-ilda';

// Пример объекта стилей
const style = {
    ".uc-qa-list-problem": {
      borderRadius: "20px 20px 0px 0px",
      background:
        "var(--gradient_bg_light, linear-gradient(0deg, #FFF 0%, rgba(240, 236, 224, 0.60) 100%))"
    },
    ".uc-qa-list-problem > div > div > ul > li > div > div.t-feed__post-imgwrapper.t-feed__post-imgwrapper_aftertitle > div": {
      borderRadius: "20px"
    },
    ".uc-qa-list-problem > div > div > ul > li > div > div.js-feed-post-title.t-feed__post-title.t-feed__post-title_margin.t-heading.t-heading_lg": {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontWeight: 600,
      textTransform: "uppercase"
    },
    ".uc-qa-list-problem > div > div > ul > li > div > div.t-feed__post-parts-date-row.t-feed__post-parts-date-row_afterdescr > span": {
      fontSize: "x-large",
      textTransform: "lowercase !important",
      color: "#BFB397"
    },
    ".uc-qa-list-problem > div > div > ul > li > div > div.t-feed__post-parts-date-row.t-feed__post-parts-date-row_afterdescr": {
      overflowX: "hidden",
      overflowY: "auto",
      wordWrap: "break-word",
      textAlign: "center"
    },
    ".uc-qa-list-problem > div > div > ul > li > div > div.t-feed__post-parts-date-row.t-feed__post-parts-date-row_afterdescr > span::after": {
      content: "none !important"
    },
// Стиль для popup
    ".uc-qa-list-problem > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__close-wrapper.t-feed__post-popup__close-wrapper_loading > div": {
      display: "none"
    },
    ".uc-qa-list-problem > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__date-parts-wrapper.t-feed__post-popup__date-parts-wrapper_aftertext > a:nth-child(1)": {
      display: "none"
    },
    ".uc-qa-list-problem > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__title-wrapper > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      fontWeight: 800,
      textTransform: "uppercase"
    },
    ".uc-qa-list-problem > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__text-wrapper > div > div": {
      alignSelf: "stretch",
      textAlign: "center",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "lowercase"
    },
    ".uc-qa-list-problem > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__date-parts-wrapper.t-feed__post-popup__date-parts-wrapper_aftertext": {
      display: "flex",
      paddingBottom: "13px",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      gap: "10px 10px",
      flexWrap: "wrap"
    },
    ".uc-qa-list-problem > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__date-parts-wrapper.t-feed__post-popup__date-parts-wrapper_aftertext > a > span": {
      margin: "0px !important",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textTransform: "lowercase !important",
      background:
        "var(--MP, linear-gradient(90deg, #DF7FC8 0.02%, #9747FF 99.98%))",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent"
    },
    ".uc-qa-list-problem > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__date-parts-wrapper.t-feed__post-popup__date-parts-wrapper_aftertext > a": {
      display: "flex",
      padding: "10px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      borderRadius: "10px",
      border: "1px solid var(--MP, #DF7FC8)"
    }
  }
  
  
  
  // Инициализация контейнера с передачей стилей
  const QAlistProblem = new MyTildaContainer(
    ['.uc-qa-list-problem','#rec768391078','#rec768177530'],
    [],
    ['/qa/**' ],
    style
  );
  
 
  
  export {QAlistProblem};