// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* awardCarouselStyles.css */

.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__title-wrapper,
.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__date-parts-wrapper_aftertitle,
.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__cover-wrapper_aftertitle {
    display: none;
}

.uc-award-carousel .t-feed__post-popup.t-popup_show>.t-feed__post-popup__container.t-popup__container-animated>div>div {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__container.t-popup__container-animated {
    padding-top: 45px !important;
}

.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__text-wrapper img {
    border-radius: 20px;
}

.uc-award-carousel {
    background: var(--gradient_bg_light, linear-gradient(0deg, #FFF 0%, rgba(240, 236, 224, 0.60) 100%));
}

.uc-award-carousel .js-feed-post .t-feed__slider-grid__wrapper.t-feed__slider-grid__content {
    display: none;
}

.uc-award-carousel .js-feed-post.t-slds__item_active .t-feed__post-bgimg.t-bgimg.loaded,
.uc-award-carousel .js-feed-post .t-feed__post-imgwrapper.t-feed__post-imgwrapper_beforetitle {
    border-radius: 20px 20px 20px 20px !important;
}

.uc-award-carousel>div.js-feed-post.t-feed__post.t-item.t-width.t-slds__item.t-slds__item_active.t-feed__grid-col.t-align_left>a>div>div>div.t-feed__slider-grid__wrapper.t-feed__slider-grid__content {
    display: none;
}`, "",{"version":3,"sources":["webpack://./dev/app/award/CasesCarusel/awardCarouselStyles.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;;;IAGI,aAAa;AACjB;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oGAAoG;AACxG;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI,6CAA6C;AACjD;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* awardCarouselStyles.css */\n\n.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__title-wrapper,\n.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__date-parts-wrapper_aftertitle,\n.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__cover-wrapper_aftertitle {\n    display: none;\n}\n\n.uc-award-carousel .t-feed__post-popup.t-popup_show>.t-feed__post-popup__container.t-popup__container-animated>div>div {\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n}\n\n.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__container.t-popup__container-animated {\n    padding-top: 45px !important;\n}\n\n.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__text-wrapper img {\n    border-radius: 20px;\n}\n\n.uc-award-carousel {\n    background: var(--gradient_bg_light, linear-gradient(0deg, #FFF 0%, rgba(240, 236, 224, 0.60) 100%));\n}\n\n.uc-award-carousel .js-feed-post .t-feed__slider-grid__wrapper.t-feed__slider-grid__content {\n    display: none;\n}\n\n.uc-award-carousel .js-feed-post.t-slds__item_active .t-feed__post-bgimg.t-bgimg.loaded,\n.uc-award-carousel .js-feed-post .t-feed__post-imgwrapper.t-feed__post-imgwrapper_beforetitle {\n    border-radius: 20px 20px 20px 20px !important;\n}\n\n.uc-award-carousel>div.js-feed-post.t-feed__post.t-item.t-width.t-slds__item.t-slds__item_active.t-feed__grid-col.t-align_left>a>div>div>div.t-feed__slider-grid__wrapper.t-feed__slider-grid__content {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
