import MyTildaComponent from '../../../lib/MyTildaComponent.js'; // Импортируем MyTildaComponent
import insertComponent from '../../../lib/insertComponent.js';  // Импортируем утилиту для вставки компонента
import MyTildaContainer from '../../../lib/MyTildaContainer.js';
import { createLogger } from '../../../lib/utils.js';


// Создаем локальный логгер для этого модуля
const log = createLogger(true);


const style = {
    ".uc-vote-button": { display: "none" }
};

const VoteButton = new MyTildaContainer (
    ['#rec787022559-1', '.uc-vote-button-1'],
    [],
    ['/award/*'],
    style,
    log
  );




// Создаем экземпляр MyTildaComponent для QaAskButton
const AwardVoteButton = new MyTildaComponent(".uc-vote-button", ['id'], [], 'awardVoteButton-1');


AwardVoteButton.init();


/**
 * Функция для добавления кнопки в элементы списка.
 * @param {HTMLElement} element - Родительский элемент, в который нужно вставить компонент.
 * @returns {Promise<boolean>} - Возвращает false, чтобы коллбек не удалялся и выполнялся каждый раз.
 */


const yellowRectangle = '<div style="width: 24px; height: 24px; background-color: yellow;"></div>';



const selector = 'div > div.js-feed.t-feed.t-feed_col > div.t-slds > div > div > div > div.js-feed-post.t-feed__post.t-item.t-width.t-slds__item.t-slds__item_active.t-feed__grid-col.t-align_left > a > div > div > div.t-feed__post-imgwrapper.t-feed__post-imgwrapper_beforetitle';
const selector1 = 'div > div.js-feed.t-feed.t-feed_col > div.t-feed__post-popup.t-popup.t-popup_show';

// const addAwardVoteButton =  (element) => {
//     // Используем утилиту для вставки компонента и указываем, что возвращать
//     console.log('Логика', element)

// };


const addAwardVoteButton = async (element) => {
  // Используем утилиту для вставки компонента и указываем, что возвращать
  return await insertComponent(element, selector1, AwardVoteButton, false);
};


export default addAwardVoteButton;
export {VoteButton};
