import { MyTildaContainer } from '../../../lib/reac-t-ilda.js';
import { addAwardLoveButton } from './AwardLove.js'
import addAwardVoteButton from './AwardVoteButton.js';
import { createLogger } from '../../../lib/utils.js';
import './awardCarouselStyles.css';
import { VoteButton } from './AwardVoteButton.js';



// console.log(styles);

const style = {

  // /* Поп Ап */

 ".uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__title-wrapper, .uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__date-parts-wrapper_aftertitle,.uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__cover-wrapper_aftertitle": {
    display: "none"
  },
".uc-award-carousel .t-feed__post-popup.t-popup_show > .t-feed__post-popup__container.t-popup__container-animated > div > div": {
    paddingLeft: "0px !important",
    paddingRight: "0px !important"
  },
".uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__container.t-popup__container-animated": {
    paddingTop: "45px !important"
  },
".uc-award-carousel .t-feed__post-popup.t-popup_show .t-feed__post-popup__text-wrapper img": {
    borderRadius: "20px"
  },
    
 
  // ".uc-award-carousel > div > div.js-feed.t-feed.t-feed_col > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__title-wrapper": {
  //   display: "none"
  // },
  // ".uc-award-carousel > div > div.js-feed.t-feed.t-feed_col > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__date-parts-wrapper.t-feed__post-popup__date-parts-wrapper_aftertitle": {
  //   display: "none"
  // },
  // ".uc-award-carousel > div > div.js-feed.t-feed.t-feed_col > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__cover-wrapper.t-feed__post-popup__cover-wrapper_aftertitle": {
  //   display: "none"
  // },
  // ".uc-award-carousel > div > div.js-feed.t-feed.t-feed_col > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div": {
  //   paddingLeft: "0px !important",
  //   paddingRight: "0px !important"
  // },
  // ".uc-award-carousel > div > div.js-feed.t-feed.t-feed_col > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated": {
  //   paddingTop: "45px !important"
  // },
  // ".uc-award-carousel > div > div.js-feed.t-feed.t-feed_col > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__text-wrapper > div > div > figure > img": {
  //   borderRadius: "20px 20px 20px 20px"
  // },

  ".uc-award-carousel": {
    // position: "relative",
    // zIndex: 2,
    // borderRadius: "20px 20px 0px 0px",
    background:
      "var(--gradient_bg_light, linear-gradient(0deg, #FFF 0%, rgba(240, 236, 224, 0.60) 100%))",
    // "margin-top": "-20px"
  },

  '.uc-award-carousel .js-feed-post .t-feed__slider-grid__wrapper.t-feed__slider-grid__content': { display: "none" },
  
  ".uc-award-carousel .js-feed-post.t-slds__item_active .t-feed__post-bgimg.t-bgimg.loaded, .uc-award-carousel .js-feed-post .t-feed__post-imgwrapper.t-feed__post-imgwrapper_beforetitle": {
    borderRadius: "20px 20px 20px 20px !important"
  },
  

  // ".uc-award-carousel::before": {
  //   content: '""',
  //   position: "absolute",
  //   top: "-20px",
  //   left: "0",
  //   right: "0",
  //   height: "20px",
  //   zIndex: -1,
  //   borderRadius: "20px 20px 0 0"
  // },
  
  ".uc-award-carousel > div.js-feed-post.t-feed__post.t-item.t-width.t-slds__item.t-slds__item_active.t-feed__grid-col.t-align_left > a > div > div > div.t-feed__slider-grid__wrapper.t-feed__slider-grid__content": {
    display: "none"
  }
  
};


// Создаем локальный логгер для этого модуля
const log = createLogger(true);



  // Инициализация контейнера с передачей стилей
  const AwardCarousel = new MyTildaContainer(
    ['.uc-award-carousel'],
    [addAwardLoveButton,addAwardVoteButton],
    ['/award/*' ],
    NaN,
    log
  );



 
  
  export {AwardCarousel};
