import { createStore } from 'zustand';
import { loadFromLocalStorage, saveToLocalStorage } from './utils.js'; 

let storeInstance;

const initialState = loadFromLocalStorage() || {
    user: {
        name: '',
        isRegistered: false,
        likes: 0, // Добавляем счетчик лайков
    },
    votes: []
};

const createMyTildaStore = () => createStore((set) => ({
    ...initialState,
    setUser: (name, isRegistered) => set((state) => {
        const newState = { ...state, user: { name, isRegistered, likes: state.user.likes } };
        saveToLocalStorage(newState);
        console.log('State after setUser:', newState);
        return newState;
    }),
    incrementLikes: () => set((state) => {
        const newLikes = state.user.likes + 1;
        const newState = { ...state, user: { ...state.user, likes: newLikes } };
        saveToLocalStorage(newState);
        console.log(`Количество лайков: ${newLikes}`); // Логируем текущее количество лайков
        console.log('State after incrementLikes:', newState);
        return newState;
    }),
    addVote: (caseId) => set((state) => {
        const newState = { ...state, votes: [...state.votes, caseId] };
        saveToLocalStorage(newState);
        console.log(`Добавлен голос: ${caseId}`);
        console.log('Текущее состояние стора:', newState); // Логируем текущее состояние стора
        return newState;
    }),
    removeVote: (caseId) => set((state) => {
        const newState = { ...state, votes: state.votes.filter((id) => id !== caseId) };
        saveToLocalStorage(newState);
        console.log('State after removeVote:', newState);
        return newState;
    }),
}));

const getStoreInstance = () => {
    if (!storeInstance) {
        storeInstance = createMyTildaStore();
    }
    return storeInstance;
};

// Создаем хук для использования стора
const useStore = getStoreInstance();

export { useStore };