import MyTildaApp from './MyTildaApp.js';
import checkRouting from './checkRouting.js';
import { validateArray, validateObject, log as defaultLog, getElementsFromSelectors, hideElements } from './utils.js';
import applyStyles from './cssStyles.js';

class MyTildaContainer {
  /**
   * Конструктор принимает селекторы, коллбеки и маршруты.
   * @param {Array<string>} selectors - Массив селекторов (ID и классы).
   * @param {Array<Function>} callbacks - Массив коллбеков (необязательный).
   * @param {Array<string>} routes - Массив маршрутов для проверки (необязательный).
   * @param {Object} style - Объект со стилями (необязательный).
   * @param {Function} [log] - Кастомный логгер (необязательный).
   */
  constructor(selectors, callbacks = [], routes = [], style = null, log) {
    // console.log('Received log function:', log);
    validateArray(selectors, 'string', 'Selectors must be an array of strings');
    validateArray(callbacks, 'function', 'Callbacks must be an array of functions');
    validateArray(routes, 'string', 'Routes must be an array of strings');

    if (style) {
      validateObject(style, 'Style must be an object with string key-value pairs');
    }

    this.selectors = selectors;
    this.callbacks = callbacks;
    this.routes = routes;
    this.style = style;
    this.observers = [];
    this.processedElements = new Set();
    this.log = log || defaultLog;
  }

  /**
   * Инициализация контейнера и добавление обзервера для всех элементов, найденных по селекторам.
   */
  init() {
    // console.log('Using log function:', this.log);
     // Преобразуем массив селекторов в строку для отображения в логах
    const selectorsText = this.selectors.join(', ');

    // Логируем сообщение с селекторами
    this.log(`Initializing MyTildaContainer for ${selectorsText}...`);

    const container = document.querySelector('.t-body') || document;
    const { elements, selectors } = getElementsFromSelectors(this.selectors, container);

    if (elements.length > 0 && selectors.length > 0) {
      this.log('Containers found:', { elements, selectors });

      if (this.routes.length === 0 || checkRouting(this.routes,this.log)) {
        if (this.style) {
          this.log('Styles are specified, applying styles...');
          applyStyles(this.style, selectors).then(() => {
            this.log('Styles applied, adding observers...');
            this._addObserver(elements);
          }).catch(error => {
            this.log('Error applying styles:', error);
          });
        } else {
          this.log('No styles specified, adding observers directly...');
          this._addObserver(elements);
        }
      } else {
        this.log('Routing check failed, hiding elements...');
        hideElements(elements);
      }
    } else {
      this.log('No containers found on the page.');
    }
    
    return this;  // Поддержка чейнинга
  }


  /**
   * Внутренний метод. Добавляет обзерверы к элементам и выполняет коллбеки при изменении элементов.
   * @param {Array<HTMLElement>} elements - Массив элементов, к которым добавляются обзерверы.
   */
  _addObserver(elements) {
    const appInstance = MyTildaApp.instance || new MyTildaApp();
    const observerFunctions = elements.map((element, index) => async () => {
      const observer = new MutationObserver(async mutations => {
        observer.disconnect();
        try {
          const appInstance = MyTildaApp.instance;
          if (!appInstance) {
            throw new Error('MyTildaApp instance not found');
          }
  
          for (let i = 0; i < this.callbacks.length; i++) {
            const result = await this.callbacks[i](element);
            if (result === true) {
              this.callbacks.splice(i, 1);
              i--;
              this.log(`Callback at index ${i} removed after execution.`);
            }
          }
  
        } catch (error) {
          this.log(`Error in callback execution:`, error);
          Sentry.captureMessage(`Error in callback execution: ${error.message}`);
        } finally {
          observer.observe(element, { attributes: true, childList: true, subtree: true });
        }
      });
  
      observer.observe(element, { attributes: true, childList: true, subtree: true });
      this.observers.push(observer);
  
      this.log(`Observer added to element:`, element);
    });
  
    if (observerFunctions.length > 0) {
      this.log(`Adding ${observerFunctions.length} observer functions to renderBatch with context _addObserver.`);
      appInstance.renderBatch(observerFunctions, '_addObserver');
    } else {
      this.log('No observer functions to add to renderBatch.');
    }
  }

  /**
   * Добавляет новые коллбеки в существующий массив коллбеков.
   * @param {Array<Function>} callbacks - Массив новых коллбеков для добавления.
   * @returns {MyTildaContainer} - Возвращает текущий экземпляр для чейнинга.
   */
  addCallbacks(callbacks) {
    validateArray(callbacks, 'function', 'Callbacks must be an array of functions');
    this.callbacks.push(...callbacks);
    return this;
  }

  /**
   * Отключает все наблюдатели.
   * Этот метод может быть использован, когда необходимо временно прекратить наблюдение за изменениями.
   */
  disconnectObservers() {
    this.observers.forEach(observer => observer.disconnect());
    this.log(`All observers disconnected.`);
  }

  /**
   * Повторно подключает все наблюдатели.
   * Этот метод может быть использован для повторного включения наблюдения за изменениями после вызова disconnectObservers.
   */
  reconnectObservers() {
    const container = document.querySelector('.t-body') || document;
    const elements = getElementsFromSelectors(this.selectors, container);
    this._addObserver(elements);
    this.log(`All observers reconnected.`);
  }

  /**
   * Внутренний метод. Обработка элемента: добавление наблюдателя или скрытие в зависимости от маршрутов.
   * @param {HTMLElement} element - Обрабатываемый элемент.
   * @param {Array<string>} routes - Массив маршрутов для проверки.
   * @param {Function} checkRouting - Функция проверки маршрутов.
   * @param {Function} addObserver - Функция добавления наблюдателя.
   * @param {Set<HTMLElement>} processedElements - Набор уже обработанных элементов.
   */
  _processElement(element, routes, checkRouting, addObserver, processedElements) {
    if (!processedElements.has(element)) {
      if (routes.length === 0 || checkRouting(routes)) {
        addObserver([element]);
      } else {
        hideElements([element]);
      }
      processedElements.add(element);
    }
  }
}

export default MyTildaContainer;
