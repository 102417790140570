
import insertComponent from '../../../lib/insertComponent.js';  // Импортируем утилиту для вставки компонента
import './awardLoveButton.css';
/**
 * Функция для добавления кнопки в элементы списка.
 * @param {HTMLElement} element - Родительский элемент, в который нужно вставить компонент.
 * @returns {Promise<boolean>} - Возвращает false, чтобы коллбек не удалялся и выполнялся каждый раз.
 */


const yellowRectangleOverlay = `
<div class="award-case-preview-overlay-container">
  <div class="heart-icon">
    <svg class="heart-svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
    </svg>
  </div>
</div>
`;



const selector1 = 'div.js-feed-post > a > div > div > div.t-feed__post-imgwrapper.t-feed__post-imgwrapper_beforetitle';
const addAwardLoveButton = async (element) => {
  // Используем утилиту для вставки компонента и указываем, что возвращать
  return await insertComponent(element, selector1, yellowRectangleOverlay, false);
};

export { addAwardLoveButton };

