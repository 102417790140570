import {  log as defaultLog } from './utils.js';

/**
 * Проверяет, содержит ли текущий путь один из маршрутов с возможностью обработки исключений и шаблонов.
 * @param {Array<string>} routes - Массив маршрутов для проверки. Маршруты могут включать шаблоны "*" и исключения "-".
 * @param {Function} [log] - Кастомный логгер (необязательный).
 * @returns {boolean} - Возвращает true, если текущий путь содержит один из маршрутов и не попадает под исключение.
 */
const checkRouting = (routes,log = defaultLog) => {
    if (!Array.isArray(routes) || routes.some(route => typeof route !== 'string')) {
        throw new Error('Routes must be an array of strings');
    }


    const currentPath = window.location.pathname;

    // Логирование текущего пути и маршрутов для проверки
     // Используем кастомный логгер или логгер по умолчанию
  log(`Checking current path: "${currentPath}" against routes: [${routes.join(', ')}]`);
  
    // Функция проверки маршрута на совпадение
    const matchRoute = (route, path) => {
        if (route === "*") {
            // Маршрут "*" совпадает с любым путем
            return true;
        } else if (route.includes("**")) {
            // Поддержка шаблонов "**" для любых вложенных ресурсов
            const baseRoute = route.replace("**", "");
            return path.startsWith(baseRoute);
        } else if (route.includes("*")) {
            // Поддержка шаблонов "*"
            const baseRoute = route.split('*')[0];
            return path.startsWith(baseRoute);
        } else {
            // Строгое соответствие пути
            return path === route;
        }
    };

    // Проверка наличия исключений
    const hasException = routes.some(route => {
        if (route.startsWith("-")) {
            const exceptionRoute = route.slice(1);
            return matchRoute(exceptionRoute, currentPath);
        }
        return false;
    });

    if (hasException) {
        if (window.loggingEnabled) {
            console.log(`Path "${currentPath}" is excluded by one of the exception routes.`);
        }
        return false;
    }

    // Проверка каждого маршрута на совпадение
    const isMatchFound = routes.some(route => {
        if (!route.startsWith("-")) {
            const isMatch = matchRoute(route, currentPath);
            if (isMatch && window.loggingEnabled) {
                console.log(`Path "${currentPath}" matches route "${route}".`);
            }
            return isMatch;
        }
        return false;
    });

    if (window.loggingEnabled) {
        console.log(`Final routing check result: ${isMatchFound}`);
    }

    return isMatchFound;
};

export default checkRouting;
