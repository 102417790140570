import MyTildaApp from './MyTildaApp.js';
import { log } from './utils.js'; // Импортируем функцию логирования

class MyTildaComponent {
  constructor(containerId, removeAttribute = [], arrayOfReplace = [], variableName = null) {
    this.containerId = containerId;
    this.removeAttribute = removeAttribute;
    this.arrayOfReplace = arrayOfReplace;
    this.variableName = variableName ? variableName.toLowerCase() : null;
    this.container = null;
    this.originalContainer = null;
  }

  async init() {
    const element = document.querySelector(this.containerId);
    if (!element) {
      // Не выводим сообщение в лог, если контейнер не найден
      return;
    }

    // Клонируем элемент и удаляем его из DOM
    this.container = element.cloneNode(true);
    this.originalContainer = element.cloneNode(true);
    
    const appInstance = MyTildaApp.instance || new MyTildaApp();

    // Добавляем задачу на удаление элемента из DOM в очередь
    await appInstance.renderBatch([async () => {
      element.remove();
      log(`Container with id=${this.containerId} removed from DOM.`);
    }]);

    // Удаляем указанные атрибуты
    await this.removeAttributes(this.container, this.removeAttribute);

    // Добавляем имя класса, если указано variableName
    if (this.variableName) {
      await this.addClassNameToContainer();
    }

    // Заменяем указанный текст
    await this.replaceText(this.container, this.arrayOfReplace);

    log(`MyTildaComponent initialized successfully for container with id=${this.containerId}.`);
    return this; // Поддержка чейнинга
  }

  async removeAttributes(element, attributes) {
    if (!element || !attributes.length) return;

    const elementsToProcess = [element, ...element.getElementsByTagName('*')];
    const appInstance = MyTildaApp.instance || new MyTildaApp();

    // Добавляем задачи на удаление атрибутов в очередь
    const tasks = elementsToProcess.map(el => {
      return async () => {
        attributes.forEach(attr => {
          if (el.hasAttribute(attr)) {
            el.removeAttribute(attr);
            log(`Attribute ${attr} removed from element.`);
          }
        });
      };
    });

    await appInstance.renderBatch(tasks);
  }

  async addClassNameToContainer() {
    const className = `uc-${this.variableName}`;
    if (!this.container.classList.contains(className)) {
      this.container.classList.add(className);
      log(`Class ${className} added to the container.`);
    }
  }

  async replaceText(container, arrayOfReplace) {
    const appInstance = MyTildaApp.instance || new MyTildaApp();
    
    // Добавляем задачи на замену текста в очередь
    const tasks = arrayOfReplace.map(replaceObj => async () => {
      for (let key in replaceObj) {
        if (container.innerHTML.includes(key)) {
          container.innerHTML = container.innerHTML.replace(key, replaceObj[key]);
          log(`Text replaced: ${key} -> ${replaceObj[key]}.`);
        } else {
          const message = `MyTildaComponent did not run: arrayOfReplace with key=${key} not found`;
          log(message);
          Sentry.captureMessage(message);
        }
      }
    });

    await appInstance.renderBatch(tasks);
  }

  setRemovableAttributes(attributes) {
    this.removeAttribute = attributes;
    return this;
  }

  setReplacement(replacements) {
    this.arrayOfReplace = replacements;
    return this;
  }

  setClass(variableName) {
    this.variableName = variableName ? variableName.toLowerCase() : null;
    return this;
  }

  get(arrayOfReplace = []) {
    this.replaceText(this.container, arrayOfReplace);
    return this.container;
  }

  clone(arrayOfReplace = []) {
    let clone = this.container.cloneNode(true);
    this.replaceText(clone, arrayOfReplace);
    return clone;
  }

  getClass() {
    if (this.variableName) {
      return `uc-${this.variableName}`;
    } else {
      return undefined;
    }
  }
}

export default MyTildaComponent;
