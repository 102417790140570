import { validateArray, log } from './utils.js';


class MyTildaApp {
  static instance;

  constructor() {
    if (MyTildaApp.instance) {
      return MyTildaApp.instance;
    }

    this.readyFunctions = [];
    this.functionCounter = 0;
    this.isRunning = false;
    this.isLocked = false; // Добавляем блокировку

    document.addEventListener("DOMContentLoaded", () => this.runFunctions());

    MyTildaApp.instance = this;
  }

  /**
   * Adds a function to the render queue and starts execution if not already running.
   * @param {Function} fn - Function to be executed.
   * @param {string} [context='Root'] - The context in which the function is executed, used for logging purposes.
   */
  render(fn, context = 'Root') {
    this.functionCounter++;
    const functionNumber = this.functionCounter;

    if (this.isFunctionInQueue(fn, context)) {
      log(`Function ${functionNumber} is already in the queue under context ${context}.`);
      return;
    }

    this.readyFunctions.push({ fn, functionNumber, context });
    log(`Function ${functionNumber} added to the queue under context ${context}.`);

    if (!this.isRunning && !this.isLocked) {
      this.runFunctions();
    }
  }

  isFunctionInQueue(fn, context) {
    return this.readyFunctions.some(item => item.fn === fn && item.context === context);
  }

  /**
   * Executes a single function from the queue.
   * @param {Function} fn - Function to be executed.
   * @param {number} functionNumber - The number of the function in the queue.
   * @param {string} context - The context in which the function is executed, used for logging purposes.
   * @returns {Promise<*>} - The result of the function execution.
   */
  async executeFunction(fn, functionNumber, context) {
    const result = await fn();
    log(`Function ${functionNumber} executed under context ${context}.`);
    if (result !== undefined) {
      log(`Result of function ${functionNumber} under context ${context}:`, result);
    }
    return result;
  }

  /**
   * Starts executing all functions from the queue.
   */
  async runFunctions() {
    if (this.isLocked) return; // Если уже выполняется, не запускаем повторно
    this.isRunning = true;
    this.isLocked = true; // Устанавливаем блокировку

    while (this.readyFunctions.length > 0) {
      const { fn, functionNumber, context } = this.readyFunctions.shift();
      await this.executeFunction(fn, functionNumber, context);
      log(`Function ${functionNumber} executed and removed from the queue under context ${context}.`);
    }

    this.isRunning = false;
    this.isLocked = false; // Снимаем блокировку
  }

  async renderBatch(functions, context = 'Batch') {
    // Проверяем, что аргумент является массивом функций
    validateArray(functions, 'function', 'Argument must be an array of functions');
  
    try {
      // Используем Set для хранения уникальных функций
      const uniqueFunctions = new Set(functions);
  
      // Создаем массив промисов, которые выполняют функции через render
      const promises = Array.from(uniqueFunctions).map((fn, index) => {
        return new Promise((resolve, reject) => {
          this.render(async () => {
            try {
              await fn();
              log(`Function ${index + 1} executed under context ${context}.`);
              resolve();
            } catch (error) {
              log(`Error executing function ${index + 1} under context ${context}:`, error);
              reject(error);
            }
          }, context);
        });
      });
  
      // Ожидаем авершения всех промисов
      await Promise.all(promises);
  
      log(`All functions in renderBatch executed under context ${context}.`);
      return true;
    } catch (error) {
      // Логируем ошибку, если что-то пошло не так
      log(`Error in renderBatch under context ${context}:`, error);
      // Sentry.captureMessage(`Error in renderBatch under context ${context}: ${error.message}`);
      return false;
    }
  }

  createRenderPromise(fn, index, context) {
    return new Promise((resolve, reject) => {
      this.render(async () => {
        try {
          await fn();
          log(`Function ${index + 1} executed under context ${context}.`);
          resolve();
        } catch (error) {
          log(`Error executing function ${index + 1} under context ${context}:`, error);
          reject(error);
        }
      }, context);
    });
  }
}





export default MyTildaApp;
