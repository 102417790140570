import settings from './settings.js'; // Импортируем настройки
import { MyTildaComponent } from '../lib/reac-t-ilda.js';
import  MyTildaApp from '../lib/MyTildaApp.js';
import { QAlistProblem } from './QA/QAlistProblem.js'
import { FooterMenuQA, FooterMenuAward } from './utils/FooterMenu.js'
import { AwardCarousel } from './award/CasesCarusel/AwardCarousel.js'
import { TopMenuAward, TopMenuQA,TopMenuAwardSlider} from './utils/TopMenu.js';
import { PreLoader } from './utils/PreLoader.js';
import AwardLoveButton, { addAwardLoveButton } from './award/CasesCarusel/AwardLove.js';
import { UserBar } from './UserProfilePanel.js';
import { VoteButton } from './award/CasesCarusel/AwardVoteButton.js'


// Применяем настройки
window.loggingEnabled = settings.loggingEnabled;



// Создаем экземпляр классаconst 
const MyApp = new MyTildaApp();


const QaAskButton = new MyTildaComponent("#rec768398765",["id", "href"],[],'QaAskButton');




MyApp.render(() => {


   PreLoader.init();

   //Award Start
   TopMenuAward.init();
   TopMenuAwardSlider.init();
   ///
   AwardCarousel.init();

   //
   VoteButton.init();
   // AwardLoveButton.init();
   ///
   FooterMenuAward.init();
   
   //Award End



   //QA Start
    TopMenuQA.init();
    QAlistProblem.init();
    QaAskButton.init();
    FooterMenuQA.init();
   //QA End




  
   //UserProfile Start 
    UserBar.init();
   
   //UserProfile End      
      
    });