import { MyTildaContainer } from '../lib/reac-t-ilda.js';
import { createLogger } from '../lib/utils.js';

// Создаем локальный логгер для этого модуля
const log = createLogger(false);


const style = { 
    ".tlk-userbar": { display: "none" }

};

  // Инициализация контейнера с передачей стилей
  const UserBar = new MyTildaContainer(
    ['.tlk-userbar'],
    [],
    [],
    style,
    log
  );



  
  export {UserBar};