import { MyTildaContainer } from '../../lib/reac-t-ilda.js';
import { createLogger } from '../../lib/utils.js';

// Создаем локальный логгер для этого модуля
const log = createLogger(true);


const style = { 
    ".uc-preloader": { display: "none" }

};

  // Инициализация контейнера с передачей стилей
  const PreLoader = new MyTildaContainer(
    ['#rec783204835','.uc-preloader'],
    [],
    ['*'],
    style,
    log
  );

// Тестовый вывод в консоль
console.log('PreLoader инициализирован:', PreLoader);


 
  
  export {PreLoader};