import MyTildaApp from './MyTildaApp.js';
import MyTildaComponent from '../lib/MyTildaComponent.js'; // Добавляем импорт MyTildaComponent
import { log } from './utils.js'; // Импортируем функцию логирования
import { useStore } from './MyTildaStore.js'; // Импортируем стор

/**
 * Асинхронно вставляет компонент или HTML-шаблон в элемент по заданному селектору,
 * с возможностью добавления нескольких событий и функций-триггеров, привязанных к определенным частям вставленного элемента.
 * @param {HTMLElement} element - Родительский элемент, в который нужно вставить компонент или HTML.
 * @param {string} selector - Селектор для поиска места вставки внутри родительского элемента.
 * @param {MyTildaComponent|string} componentOrHtml - Компонент MyTildaComponent или HTML-шаблон для вставки.
 * @param {object} [config={}] - Объект конфигурации, содержащий параметры для вставки и привязки событий.
 * @param {any} [config.returnValue=false] - Значение, которое утилита должна вернуть в случае успеха.
 * @param {string} [config.position='append'] - Позиция вставки ('append' для добавления в конец, 'prepend' для добавления в начало).
 * @param {Array<{selector: string, event: string, handler: function}>} [config.events=[]] - Массив объектов событий для привязки.
 * @param {Array<{selector: string, bindTo: function, updateFunction: function}>} [config.storeBindings=[]] - Массив объектов для привязки к стору.
 * @returns {Promise<any>} - Возвращает указанное значение или false по умолчанию.
 */
const insertComponent = async (element, selector, componentOrHtml, config = {}) => {
  const {
    returnValue = false,
    position = 'append',
    events = [],
    storeBindings = []
  } = config;

  const appInstance = MyTildaApp.instance || new MyTildaApp();
  let componentClass = null;
  let htmlContent = null;

  // Определяем, передан ли компонент MyTildaComponent или HTML-шаблон
  const isMyTildaComponent = componentOrHtml instanceof MyTildaComponent;

  if (isMyTildaComponent) {
    componentClass = componentOrHtml.getClass();
    if (!componentClass) {
      console.error('Cannot add component: undefined component class');
      Sentry.captureMessage('Cannot add component: undefined component class');
      return returnValue;
    }
  } else if (typeof componentOrHtml === 'string') {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = componentOrHtml;
    const firstElement = tempDiv.firstElementChild;
    componentClass = firstElement && firstElement.className ? firstElement.className : null;
    htmlContent = tempDiv.innerHTML.trim(); // Сохраняем HTML-контент для проверки
  } else {
    console.error('Invalid component or HTML provided.');
    return returnValue;
  }

  const targetElements = element.querySelectorAll(selector);
  const maxElements = 100;
  const elementsToProcess = Array.from(targetElements).slice(0, maxElements); // Ограничиваем количество обрабатываемых элементов

  let componentAdded = false;

  // Логируем контекст и количество элементов для вставки
  log(`Attempting to add component to ${elementsToProcess.length} elements using selector: ${selector}`);

  // Создаем задачи для вставки компонента и добавляем их в очередь
  const tasks = elementsToProcess.map(target => async () => {
    let alreadyExists = false;

    if (isMyTildaComponent) {
      alreadyExists = !!target.querySelector(`.${componentClass}`);
    } else if (htmlContent) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = componentOrHtml;
      const firstElement = tempDiv.firstElementChild;
      const elementId = firstElement && firstElement.id ? firstElement.id : null;
      const elementClass = firstElement && firstElement.className ? firstElement.className : null;

      if (elementId) {
        alreadyExists = !!target.querySelector(`#${elementId}`);
      } else if (elementClass) {
        alreadyExists = !!target.querySelector(`.${elementClass}`);
      } else {
        alreadyExists = !!target.querySelector(`.${componentClass}`);
      }
    }

    if (!alreadyExists) {
      let clonedElement;
      if (isMyTildaComponent) {
        clonedElement = componentOrHtml.clone();
      } else {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = componentOrHtml;
        clonedElement = tempDiv.firstElementChild.cloneNode(true);
      }

      // Вставка компонента в указанную позицию
      if (position === 'prepend') {
        target.insertBefore(clonedElement, target.firstChild);
      } else {
        target.appendChild(clonedElement);
      }

      // Логирование ссылки на clonedElement
      log('Cloned element:', clonedElement);

      // Логирование конфигурации перед привязкой событий
      log('Config for event binding:', events);

      // Привязка событий к элементам
      events.forEach(({ selector, event, handler }) => {
        const eventTargets = selector ? clonedElement.querySelectorAll(selector) : [clonedElement];
        log(`Event targets for selector '${selector}':`, eventTargets);
        eventTargets.forEach(eventTarget => {
          eventTarget.addEventListener(event, handler);
          log(`Event handler for event '${event}' added to element with selector '${selector}'`);
        });
      });

      // Привязка к стору
      storeBindings.forEach(({ selector, bindTo, updateFunction }) => {
        const targetElement = selector ? clonedElement.querySelector(selector) : clonedElement;
        if (targetElement) {
          // Привязываем начальное состояние
          const initialState = bindTo();
          updateFunction(targetElement, initialState);

          // Подписка на изменения в сторе
          const unsubscribe = useStore.subscribe((newState) => {
            updateFunction(targetElement, bindTo(newState));
          });

          // Добавляем возможность отписки, если необходимо
          targetElement._unsubscribe = unsubscribe;
        }
      });

      componentAdded = true;
      log(`Component or HTML added to element with ID: ${element.id} at position: ${position}`);
    } else {
      log(`Component or HTML already exists in element with ID: ${element.id}, skipping insertion.`);
    }
  });

  await appInstance.renderBatch(tasks);

  if (!componentAdded) {
    log(`No new components or HTML added using selector: ${selector} in element with ID: ${element.id}`);
  }

  // Возвращаем указанное значение или false по умолчанию
  return returnValue;
};

export default insertComponent;
