import { MyTildaContainer } from '../../lib/reac-t-ilda.js';


const style = {
    
    ".uc-top-menu > div > div,#rec783935228": {
        borderRadius: "0px 0px 20px 20px", 
        position: "relative",
        zIndex: 3,
        "margin-bottom": "-20px"
     }
   
};

  // Инициализация контейнера с передачей стилей
  const TopMenuQA = new MyTildaContainer(
    ['#rec726697677'],
    [],
    ['/qa/*' ],
    style
  );


  const TopMenuAward= new MyTildaContainer(
    ['#rec782368004'],
    [],
    ['/award/*' ],
    style
  );

  const TopMenuAwardSlider= new MyTildaContainer(
    ['#rec783935228'],
    [],
    ['/award/2024/*' ],
    style
  );
  
 
  
  export {TopMenuQA,TopMenuAward,TopMenuAwardSlider};