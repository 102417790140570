// utils.js
import MyTildaApp from './MyTildaApp.js';  // Импортируем MyTildaApp для использования в hideElements

/**
 * Утилиты:
 * 1. validateArray - Проверяет, что массив содержит элементы определенного типа.
 * 2. validateObject - Проверяет, что объект содержит пары ключ-значение типа строка.
 * 3. createLogger - Создает контекстно-зависимый логгер.
 * 4. log - Логирует сообщения в консоль, если логирование включено.
 * 5. getElementsFromSelectors - Формирует список уникальных элементов на основе селекторов.
 * 6. hideElements - Асинхронно скрывает элементы, редактируя или создавая атрибут стиля.
 * 7. saveToLocalStorage - Сохраняет состояние в localStorage.
 * 8. loadFromLocalStorage - Загружает состояние из localStorage.
 */

/**
 * Validates an array to ensure it contains elements of a specific type.
 * @param {Array} array - The array to be validated.
 * @param {string} type - The expected type of the array elements.
 * @param {string} errorMsg - The error message if validation fails.
 */
export const validateArray = (array, type, errorMsg) => {
  if (!Array.isArray(array) || array.some(item => typeof item !== type)) {
    throw new Error(errorMsg);
  }
};

/**
 * Validates that the provided object contains string key-value pairs.
 * @param {Object} obj - The object to validate.
 * @param {string} errorMsg - The error message if validation fails.
 */
export const validateObject = (obj, errorMsg) => {
    if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
      for (const [key, value] of Object.entries(obj)) {
        if (typeof key !== 'string') {
          throw new Error(errorMsg);
        }
        if (typeof value !== 'string' && typeof value !== 'object') {
          throw new Error(errorMsg);
        }
      }
    } else {
      throw new Error(errorMsg);
    }
};
  
/**
 * Фабрика для создания контекстно-зависимого логгера.
 * @param {boolean} [localLoggingEnabled=false] - Локальный флаг для логирования.
 * @returns {Function} - Функция log с доступом к локальной переменной.
 */
export const createLogger = (localLoggingEnabled = false) => {
  return (message, data = null) => {
    if (localLoggingEnabled || window.loggingEnabled) {
      console.log(message, data);
    }
  };
};

/**
 * Logs messages to the console if logging is enabled.
 * @param {string} message - The message to be logged.
 * @param {any} [data=null] - Additional data to be logged.
 */
export const log = (message, data = null) => {
  if (window.loggingEnabled) {
    console.log(message, data);
  }
};


/**
 * Forms a complete list of elements based on the provided selectors, excluding intersections.
 * @param {Array<string>} selectors - The array of selectors.
 * @param {HTMLElement} [target=document] - The element within which the search is conducted.
 * @returns {Object} - Object containing unique elements and the selectors used for their selection.
 */
export const getElementsFromSelectors = (selectors, target = document) => {
    const elements = new Set();
    const usedSelectors = new Set();
    const classSelectors = selectors.filter(selector => selector.startsWith('.'));
    const idSelectors = selectors.filter(selector => selector.startsWith('#'));
  
    // Process class selectors first
    classSelectors.forEach(classSelector => {
      target.querySelectorAll(classSelector).forEach(element => {
        elements.add(element);
        usedSelectors.add(classSelector);
      });
    });
  
    // Process ID selectors, ensuring no intersections with class-selected elements
    idSelectors.forEach(idSelector => {
      const element = target.querySelector(idSelector);
      if (element) {
        const elementClasses = element.className.split(' ');
        const hasConflictingClass = classSelectors.some(classSelector => {
          const className = classSelector.slice(1);
          return elementClasses.includes(className);
        });
  
        if (!hasConflictingClass) {
          elements.add(element);
          usedSelectors.add(idSelector);
        } else {
          log(`Intersection found: element with ID ${idSelector} also contains one of the class selectors. Element added based on class selector.`);
        }
      }
    });
  
    log(`Elements selected from selectors:`, Array.from(elements));
    return {
      elements: Array.from(elements),
      selectors: Array.from(usedSelectors)
    };
  };

/**
 * Hides elements asynchronously by editing or creating the style attribute.
 * @param {Array<HTMLElement>} elements - The array of elements to be hidden.
 * @param {string} [context='HideElements'] - The context in which the functions are executed, used for logging purposes.
 */
export const hideElements = async (elements, context = 'HideElements') => {
    const appInstance = MyTildaApp.instance || new MyTildaApp();
    const hideFunctions = elements.map((element, index) => async () => {
      const originalStyle = element.getAttribute('style') || '';
      const newStyle = `${originalStyle}; display: none;`;
      element.setAttribute('style', newStyle);
      log(`Element style updated.`, { element, originalStyle, newStyle });
    });
    log(`Adding ${hideFunctions.length} functions to renderBatch under context ${context}.`);
    await appInstance.renderBatch(hideFunctions, context);
  };


    
/**
 * Сохраняет состояние в localStorage.
 * @param {Object} state - Состояние, которое нужно сохранить.
 */
export const saveToLocalStorage = (state) => {
    try {
        console.log('Сохранение состояния в localStorage:', state); // Логируем состояние перед сохранением
        localStorage.setItem('appState', JSON.stringify(state));
        console.log('Состояние успешно сохранено в localStorage.');
    } catch (error) {
        console.error('Ошибка при сохранении состояния в localStorage:', error);
    }
};

/**
 * Загружает состояние из localStorage.
 * @returns {Object} - Состояние, если оно существует, иначе undefined.
 */
export const loadFromLocalStorage = () => {
    try {
        const state = localStorage.getItem('appState');
        if (state) {
            const parsedState = JSON.parse(state);
            console.log('Состояние загружено из localStorage:', parsedState); // Логируем загруженное состояние
            return parsedState;
        } else {
            console.log('Состояние не найдено в localStorage.');
            return undefined;
        }
    } catch (error) {
        console.error('Ошибка при загрузке состояния из localStorage:', error);
        return undefined;
    }
};

