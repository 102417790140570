import MyTildaApp from './MyTildaApp.js';
import { log } from './utils.js'; // Импортируем функцию логирования

/**
 * Асинхронно применяет стили к указанным селекторам.
 * @param {Object} style - Объект со стилями. Может быть объектом стилей или импортированным модулем CSS.
 * @param {Array<string>} selectors - Массив селекторов.
 * @returns {Promise<void>}
 */
const applyStyles = async (style, selectors) => {
  const appInstance = MyTildaApp.instance || new MyTildaApp();

  // Определяем идентификатор контейнера стилей
  let styleId = null;
  for (const selector of selectors) {
    if (selector.startsWith('.')) {
      styleId = selector.slice(1); // Убираем точку в начале
      break;
    }
    if (!styleId && selector.startsWith('#')) {
      styleId = `app_${selector.slice(1)}`; // Убираем решетку и добавляем префикс
    }
  }

  if (!styleId) {
    log('No valid selector found for style ID.');
    return;
  }

  // Функция для преобразования стилей из объекта в строку CSS
  const convertStyles = (styles) => {
    return Object.entries(styles).map(([prop, value]) => {
      const kebabProp = prop.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase(); // Переводим camelCase в kebab-case
      return `${kebabProp}: ${value};`;
    }).join(' ');
  };

  // Сборка стилей в зависимости от типа селектора и источника стилей (объект или модуль)
  const styleContent = Object.entries(style).map(([selector, styles]) => {
    let modifiedSelector = selector;

    // Проверяем, если в селекторе есть фрагмент 't-feed__post-popup__container' и в массиве selectors есть ID
    if (selectors.some(sel => sel.startsWith('#')) && selector.includes('t-feed__post-popup__container')) {
      const firstClassSelector = selector.match(/\.[^ >]+/);
      if (firstClassSelector) {
        const className = firstClassSelector[0].slice(1); // Получаем имя класса без точки
        modifiedSelector = selector.replace(`.${className}`, `#${selectors.find(sel => sel.startsWith('#')).slice(1)}`);
      }
    }

    // Если стили представлены в виде строки (как в модуле CSS), используем их напрямую
    if (typeof styles === 'string') {
      return `${modifiedSelector} { ${styles} }`;
    } 
    // Если стили представлены в виде объекта, конвертируем их в строку CSS
    else if (typeof styles === 'object') {
      const styleString = convertStyles(styles);
      return `${modifiedSelector} { ${styleString} }`;
    }
  }).join(' ');

  log(`Creating style with ID: ${styleId}`);
  const styleElement = document.createElement('style');
  styleElement.id = styleId;
  styleElement.textContent = styleContent;
  document.head.appendChild(styleElement);
  log(`Style element added to document head with id: ${styleId}`);

  try {
    log(`Adding style function to renderBatch.`);
    await appInstance.renderBatch([async () => {
      log(`Style applied with ID: ${styleId}`);
    }], 'applyStyles');
    log('All styles applied successfully.');
  } catch (error) {
    log('Error applying styles:', error);
  }
};

export default applyStyles;
