import { MyTildaContainer } from '../../lib/reac-t-ilda.js';


const style = {
    
    ".uc-footer-menu": {
        borderRadius: "20px 20px 0px 0px",
        position: "relative",
        zIndex: 1000,
        "margin-top": "-20px",
    },
   ".uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post,\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:first-child,\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:last-child,\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:nth-child(-n+2),\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:nth-of-type(n),\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:first-of-type,\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:last-of-type,\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:only-child,\n.uc-footer-menu > div > div > ul > li.js-feed-post.t-feed__post:only-of-type": {
    marginTop: "5px !important"
  },
    
    ".uc-footer-menu > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__close-wrapper.t-feed__post-popup__close-wrapper_loading > div": {
        display: "none"
    },

    ".uc-footer-menu > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__title-wrapper": {
        display: "none"
    },
    ".uc-footer-menu > div > div > div.t-feed__post-popup.t-popup.t-popup_show > div.t-feed__post-popup__container.t-container.t-popup__container.t-popup__container-static.t-popup__container-animated > div > div > div.t-feed__post-popup__date-parts-wrapper.t-feed__post-popup__date-parts-wrapper_aftertitle": {
        display: "none"
    },
      // стиль для блока с дисклемером и разделитель
    "#rec784126969.uc-footer-menu,#rec784126786":{
        borderRadius: "0px 0px 0px 0px",
        position: "relative",
        zIndex: 2,
    }
   

};

  // Инициализация контейнера с передачей стилей
  const FooterMenuQA = new MyTildaContainer(
    ['#rec781021801'],
    [],
    ['/qa/*' ],
    style
  );


  const FooterMenuAward= new MyTildaContainer(
    ['#rec781025809','#rec784126969','#rec784126786'],
    [],
    ['/award/*' ],
    style
  );
  
 
  
  export {FooterMenuQA,FooterMenuAward};